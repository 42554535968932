<script setup>
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  hasFloatLabel: {
    type: Boolean,
    default: false,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 500,
  },
});

const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <div class="r-slider">
    <div class="green-area" />
    <client-only>
      <slider
        v-model="model"
        data-cy="slider"
        class="l-slider"
        :tooltips="hasFloatLabel"
        :min="min"
        :max="max"
        :step="1"
        :lazy="false"
      />
    </client-only>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.r-slider {
  @apply flex items-center;
}

.l-slider {
  --slider-handle-bg: #386cf6;
  --slider-connect-bg: #386cf6;
  --slider-height: 14px;
  --slider-handle-shadow: none;
  --slider-handle-shadow-active: none;
}
.slider-target {
  @apply w-full relative box-border select-none touch-none tap-highlight-transparent touch-callout-none rounded-tl-none rounded-bl-none rounded-tr-full rounded-br-full #{!important};
}

.slider-horizontal {
  @apply h-[14px];
}

.slider-base {
  @apply w-full h-full relative z-1 bg-gray-300 rounded-tl-none rounded-bl-none rounded-tr-full rounded-br-full #{!important};
}

:deep(.slider-connects) {
  @apply rounded-none;
}

:deep(.slider-connect) {
  @apply rounded-tr-full rounded-br-full absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-primary-900 cursor-pointer tap:duration-100 tap:transition-transform rounded-tl-none rounded-bl-none #{!important};
}

:deep(.slider-handle) {
  @apply absolute rounded-full bg-white border border-solid border-info-500 cursor-pointer focus:outline-none h:w-[22px] h:h-[22px] h:-top-1 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto;
}

:deep(.slider-touch-area) {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-4 w-4 bg-primary-900 rounded-full;
}

.green-area {
  @apply bg-info-500 w-1/6;
  height: rem(14px);
  border-radius: rem(11px) 0 0 rem(11px);
}
</style>
